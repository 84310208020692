<template>
  <articleBase title="Typing Faster">
    <p>
      Go on
      <a href="https://monkeytype.com/" target="_blank">monkeytype</a>
      right now and start typing; write down your score somewhere you will be
      able to find back in a few weeks/months.
    </p>

    <p>
      Most of us use computers and therefore keyboards on a daily basis. The
      thing is that also most of us are very slow at using them... <br />
      In some cases, schools teach their students how to type correctly and
      fast, but this doesn't happen often. <br />
      If your score is below 50WPM, you are probably not using a correct
      technique. But don't worry, you can learn how to !
      <br />
      It can be very frustrating at the begining but trust me, it will quickly
      get satisfying and you will not regret putting the effort in. What you
      need to do is to "re-learn" how to type. Your goal will be to keep your
      eyes off the keyboard and reach the keys by only using your muscle memory.
      <br />
      This will allow two things : typing faster as you don't need to look for
      the keys and better output from your work as you only focus on writing and
      not on searching.
      <br />
      To help you being more efficient and make the learning process more
      enjoyable, recommend you to use
      <a href="https://www.typing.com/" target="_blank">typing.com</a>. This
      website will teach you how to type the keys with the appropriate fingers
      in an interactive way. Once you think you adopted the write habits, you
      can move to monkeytype. <br />
			Here are some keypoints I found important to keep in mind :
			<ul>
				<li>Really force yourself to keep the good habits</li>
				<li>Stretch your wrists/fingers before typing</li>
				<li>Take breaks every 20 minutes</li>
				<li>Keep your resting fingers on the home row</li>
				<li>Always aim for the opposite hand to capitalize letters</li>
			</ul>
    </p>
    <p class="note">
      <span class="underline">Quick note about monkeytype :</span> this website
      is great as it is very customizable and brings you many possibilities.
      However, the default settings give you random words without punctiation
      nor special characters/numbers. You can use random wikipedia articles (in
      the settings) which will be closer to real life sentences.
    </p>
    <p>
      As numbers talk better here are a few from me : <br />
      I started with a score of around 40wpm, from there with the new method I
      was at ~30wpm and after a month and a half, I went all the way to
      65-70wpm. I was spending 30-ish minutes a day practicing (more in the
      begining as I found it really enjoyable). <br />The regression I went
      through was difficult but you need to keep in mind that the typing
      experience was completely different as I was not looking for the keys
      anymore. You will probably find yourself very quickly typing words
      automatically as you think about them, a very sweet feeling !
    </p>
  </articleBase>
</template>

<script charset="utf-8">
import articleBase from "@/components/articleBase.vue";
export default {
  components: {
    articleBase,
  },
};
</script>
